import {del, get, post, postAndDownload} from "../common/ApiUtils";

export const ReportApi = {

    getUamData(request) {
        return post('/api/reports/uam', request)
    },

    exportUamData(request) {
        return postAndDownload('/api/reports/uam/export', request, "uam_report.csv")
    },

    getUamSkanOnlyData(request) {
        return post('/api/reports/uam-skan-only', request)
    },

    exportUamSkanOnlyData(request) {
        return postAndDownload('/api/reports/uam-skan-only/export', request, "uam_skan_only_report.csv")
    },

    getRetargetingData(request) {
        return post('/api/reports/retargeting', request)
    },

    exportRetargetingData(request) {
        return postAndDownload('/api/reports/retargeting/export', request, "retargeting.csv")
    },

    getEventData(request) {
        return post('/api/reports/event', request)
    },

    exportEventData(request) {
        return postAndDownload('/api/reports/event/export', request, "event.csv")
    },

    getCreativeData(request) {
        return post('/api/reports/creative', request)
    },

    exportCreativeData(request) {
        return postAndDownload('/api/reports/creative/export', request, "creative.csv")
    },

    getAsoData(request) {
        return post('/api/reports/aso', request)
    },

    saveProfile(profile) {
        return post('/api/users/profiles', profile)
    },

    deleteProfile(profileId) {
        return del('/api/users/profiles/' + profileId)
    },

    listAvailableProfiles(profileKey) {
        return get("/api/users/profiles/" + profileKey)
    }
}
