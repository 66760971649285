import React, {Component} from 'react';
import {Collapse, Nav, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink} from 'reactstrap';
import {NavLink as Link} from 'react-router-dom';
import moment from "moment";
import Dropdown from "reactstrap/es/Dropdown";
import DropdownToggle from "reactstrap/es/DropdownToggle";
import DropdownMenu from "reactstrap/es/DropdownMenu";
import DropdownItem from "reactstrap/es/DropdownItem";
import {Escape} from "react-bootstrap-icons";
import {deleteCookie} from "../common/Utils";

export default class AppNavbar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            hlrOpen: false,
            toolsOpen: false
        };
        this.toggle = this.toggle.bind(this);
        this.hlrToggle = this.hlrToggle.bind(this);
        this.logout = this.logout.bind(this);
    }

    toggle() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    hlrToggle() {
        this.setState({
            hlrOpen: !this.state.hlrOpen
        });
    }

    logout() {
        deleteCookie('AUTHORIZATION')
        document.location.href = "/";
    }


    render() {
        let beforeYesterday = moment().subtract(2, 'days').format("YYYY-MM-DD");
        let yesterday = moment().subtract(1, 'days').format("YYYY-MM-DD");
        let fourMonthAgo = moment().subtract(1, 'days').subtract(4, 'month').format("YYYY-MM-DD");
        let monthAgo = moment().subtract(1, 'month').format("YYYY-MM-DD");
        let lastWeekStart = moment().subtract(1, 'week').startOf('isoWeek').format("YYYY-MM-DD");
        let lastWeekEnd = moment().subtract(1, 'week').endOf('isoWeek').format("YYYY-MM-DD");


        let hlrMain = 'http://redash.belka-games.com/dashboard/ua-main-dashboard?p_app_name=Clockmaker&p_platform=all&p_media_source=all&p_country_code=all&p_install_date.start=' + monthAgo + '&p_install_date.end=' + yesterday + '&p_granulation=Day&p_traffic_type=Belka%20UA&p_Days_before=60';
        let hlrGEO = 'http://redash.belka-games.com/dashboard/ua-auxiliary-geo?p_app_name=Clockmaker&p_platform=all&p_traffic_type=Belka%20UA&p_media_source=all&p_country_code=all&p_install_date.start=' + monthAgo + '&p_install_date.end=' + yesterday + '&p_granulation=day';
        let hlrMediasource = 'http://redash.belka-games.com/dashboard/ua-auxiliary-mediasource?p_app_name=Clockmaker&p_platform=all&p_media_source=all&p_country_code=all&p_install_date.start=' + monthAgo + '&p_install_date.end=' + yesterday + '&p_granulation=day&p_traffic_type=Belka%20UA';
        let hlrValidation = 'http://redash.belka-games.com/dashboard/validation-dashboard?p_event_date.start=' + monthAgo + '&p_event_date.end=' + beforeYesterday + '&p_app_code=cm&p_platform=io&p_country=All&p_with_retargeting=TRUE&p_time_group=Day&p_with_refund=FALSE';
        let hlrCompas = 'https://redash.belka-games.com/dashboard/roas-rpi-applovin-like-?p_app_name=Clockmaker&p_platform=all&p_traffic_type=Belka%20UA&p_media_source=all&p_country_code=all&p_install_date.start=' + monthAgo + '&p_install_date.end=' + yesterday + '&p_granulation=Day&p_Days_before=60&p_metrics=ROAS';
        let hlrInstallsByOS = 'https://redash.belka-games.com/dashboard/ua-main-installs-?p_app_name=Clockmaker&p_platform=an&p_traffic_type=all&p_media_source=all&p_country_code=all&p_install_date.start=' + monthAgo + '&p_install_date.end=' + yesterday + '&p_granulation=Day&p_Days_before=0&p_last_versions=yes';


        let hlrTargetCalculator = 'https://redash.belka-games.com/dashboard/model-target-roas?p_app_code=%5B%22cm%22%5D&p_platform=%5B%22an%22%5D&p_media_source=%5B%22all%22%5D&p_campaign_type=other&p_country=%5B%22US%22%5D&p_metric=tROAS&p_margin%20%25=0&p_net%2Fgross=net&p_roas_type=combined&p_w21218_with_boost=no&p_w21219_compare_with=2023-03-01&p_w21219_with_boost=no&p_with_boost=yes&p_install_dates.start=' + fourMonthAgo + '&p_install_dates.end=' + yesterday + '&p_target_day=360';
        let hlrDiffDays = 'https://redash.belka-games.com/dashboard/forecast-roas-from-different-days_2?p_install_date.start=2021-04-01&p_install_date.end=2021-10-31&p_traffic_type=%5B%22paid%22%5D&p_platform=%5B%22all%22%5D&p_media_source=%5B%22all%22%5D&p_campaign_type=%5B%22All%22%5D&p_country=%5B%22All%22%5D&p_model_type=%5B%22ad%22,%22inapp%22%5D&p_with_organic=FALSE&p_actual_model=FALSE&p_with_retargeting=FALSE&p_with_skan=FALSE';
        let hlrSkad = 'https://redash.belka-games.com/dashboard/skad';

        let monthlyReport = 'https://redash.belka-games.com/dashboard/model-roas-achievements_1?p_app_code=%5B%22cm%22%5D&p_platform=%5B%22all%22%5D&p_media_source=%5B%22all%22%5D&p_campaign_type=%5B%22all%22%5D&p_country=%5B%22all%22%5D&p_traffic_type=%5B%22paid%22%5D&p_predict_day=7&p_model_type=%5B%22ad%22,%22inapp%22%5D'
        let customDatesReport = 'https://redash.belka-games.com/dashboard/model-roas-achievements-custom-dates-?p_app_code=%5B%22cm%22%5D&p_platform=%5B%22all%22%5D&p_campaign_type=%5B%22all%22%5D&p_country=%5B%22all%22%5D&p_media_source=%5B%22all%22%5D&p_traffic_type=%5B%22paid%22%5D&p_install_date.start=' + lastWeekStart + '&p_install_date.end=' + lastWeekEnd + '&p_predict_day=7&p_model_type=%5B%22ad%22,%22inapp%22%5D'

        return <Navbar color="dark" dark expand="md">
            <NavbarBrand tag={Link} to="/">Dashboard</NavbarBrand>
            <NavbarToggler onClick={this.toggle}/>
            <Collapse isOpen={this.state.isOpen} navbar>
                <Nav className="ml-auto" navbar>
                    {
                        this.props.authorities.includes("READ_HL_REPORT") ?
                            <Dropdown nav isOpen={this.state.hlrOpen} toggle={this.hlrToggle}>
                                <DropdownToggle nav caret>
                                    HLR
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem href={hlrMain} target="_blank" rel="noreferrer"
                                                  activeclassname="active"><b>UA
                                        Main</b></DropdownItem>
                                    <DropdownItem href={hlrCompas} target="_blank" rel="noreferrer"
                                                  activeclassname="active"><b>UA
                                        Main by period</b></DropdownItem>
                                    <DropdownItem href={hlrInstallsByOS} target="_blank" rel="noreferrer"
                                                  activeclassname="active">UA installs by OS %</DropdownItem>
                                    <DropdownItem href={hlrTargetCalculator} target="_blank" rel="noreferrer"
                                                  activeclassname="active">Target Calculator</DropdownItem>
                                    <DropdownItem href={hlrGEO} target="_blank" rel="noreferrer"
                                                  activeclassname="active">UA Auxilliary
                                        by
                                        GEO</DropdownItem>
                                    <DropdownItem href={hlrMediasource} target="_blank" rel="noreferrer"
                                                  activeclassname="active">UA
                                        Auxilliary
                                        by Mediasource</DropdownItem>
                                    <DropdownItem href={hlrValidation} target="_blank" rel="noreferrer"
                                                  activeclassname="active"><b>Validation</b></DropdownItem>
                                    <DropdownItem href={hlrDiffDays} target="_blank" rel="noreferrer"
                                                  activeclassname="active">Forecast ROAS by different
                                        days</DropdownItem>
                                    <DropdownItem href={hlrSkad} target="_blank" rel="noreferrer"
                                                  activeclassname="active">SKAD</DropdownItem>
                                    <DropdownItem divider/>
                                    <DropdownItem header>Achievements</DropdownItem>
                                    <DropdownItem href={monthlyReport} target="_blank" rel="noreferrer"
                                                  activeclassname="active">Monthly</DropdownItem>
                                    <DropdownItem href={customDatesReport} target="_blank" rel="noreferrer"
                                                  activeclassname="active">Custom dates</DropdownItem>
                                </DropdownMenu>
                            </Dropdown> : null
                    }
                    {
                        this.props.authorities.includes("READ_UAM_REPORT") ?
                            <NavItem>
                                <NavLink tag={Link} to="/uam" activeclassname="active">UAM</NavLink>
                            </NavItem> : null
                    }
                    {
                        this.props.authorities.includes("READ_UAM_REPORT") ?
                            <NavItem>
                                <NavLink tag={Link} to="/uamSkanOnly" activeclassname="active">SKAN</NavLink>
                            </NavItem> : null
                    }
                    {
                        this.props.authorities.includes("READ_UAM_REPORT") ?
                            <NavItem>
                                <NavLink tag={Link} to="/event" activeclassname="active">Event</NavLink>
                            </NavItem> : null
                    }
                    {
                        this.props.authorities.includes("READ_DEVICE_REPORT") ?
                            <NavItem>
                                <NavLink tag={Link} to="/device" activeclassname="active">Device</NavLink>
                            </NavItem> : null
                    }
                    {
                        this.props.authorities.includes("READ_RETARGETING_REPORT") ?
                            <NavItem>
                                <NavLink tag={Link} to="/retargeting" activeclassname="active">Retargeting</NavLink>
                            </NavItem> : null
                    }
                    {
                        this.props.authorities.includes("READ_CREATIVE_REPORT") ?
                            <NavItem>
                                <NavLink tag={Link} to="/creative" activeclassname="active">Creative</NavLink>
                            </NavItem> : null
                    }
                    {
                        this.props.authorities.includes("READ_ASO_REPORT") ?
                            <NavItem>
                                <NavLink tag={Link} to="/aso" activeclassname="active">ASO Report</NavLink>
                            </NavItem> : null
                    }
                    <NavItem>
                        <NavLink tag={Link} to="/tool" activeclassname="active">Tools</NavLink>
                    </NavItem>
                </Nav>
                <Escape color="rgba(255,255,255,.5)"
                        style={{cursor: 'pointer', marginLeft: '0.5rem'}}
                        onClick={this.logout}/>
            </Collapse>
        </Navbar>;
    }
}
