import {FilterType, ReportUtil} from "./ReportUtils";
import {CatalogApi} from "../../api/CatalogApi";
import {DEFAULT_MULTISELECT_VALUE} from "../../components/CustomMultiSelect/MultiSelect";

const WEEK_START_OPTIONS = [
    {label: "Monday", value: 0},
    {label: "Tuesday", value: 1},
    {label: "Wednesday", value: 2},
    {label: "Thursday", value: 3},
    {label: "Friday", value: 4},
    {label: "Saturday", value: 5},
    {label: "Sunday", value: 6},
];

const ADDITIONAL_FILTERS_OPTIONS = [
    {label: "Hide paused", value: 'hidePaused'},
    {label: "Hide inactive", value: 'hideInactive'},
    {label: "Hide autobidder", value: 'hideAutobidderCampaigns'},
    {label: "Show SKAN", value: 'showSKAN'},
    {label: "Core Only", value: 'coreOnly'},
    {label: "Test Only", value: 'testOnly'},
];

const TRAFFIC_TYPE_OPTIONS = [
    {label: "All", value: "All"},
    {label: "Paid", value: "paid"},
    {label: "Organic", value: "organic"},
    {label: "Applovin", value: "applovin"},
    {label: "Tests", value: "tests"},
    {label: "Retargeting", value: "retargeting"},
    {label: "Applovin Retargeting", value: "applovin_retargeting"},
];

const CREATIVE_TYPE_OPTIONS = [
    {value: "All", label: "All"},
    {value: "video", label: "Video"},
    {value: "playable", label: "Playable"},
];

const CREATIVE_REPORT_COUNTRY_OPTIONS = [
    {value: "All", label: "All"},
    {value: "AU", label: "AU"},
    {value: "BR", label: "BR"},
    {value: "CA", label: "CA"},
    {value: "CN", label: "CN"},
    {value: "DE", label: "DE"},
    {value: "ES", label: "ES"},
    {value: "FR", label: "FR"},
    {value: "IT", label: "IT"},
    {value: "JP", label: "JP"},
    {value: "KR", label: "KR"},
    {value: "RU", label: "RU"},
    {value: "TW", label: "TW"},
    {value: "UK", label: "UK"},
    {value: "US", label: "US"},
    {value: "REST", label: "REST"},
];

const CREATIVE_REPORT_MEDIA_SOURCES = [
    {value: "All", label: "All"},
    {value: "Facebook Ads", label: "Facebook Ads"},
    {value: "googleadwords_int", label: "Google Ads"},
    {value: "liftoff_int", label: "Liftoff"},
    {value: "unityads_int", label: "Unity Ads"},
    {value: "vungle_int", label: "Vungle"},
    {value: "fb_new", label: "FB new"},
    {value: "bytedanceglobal_int", label: "Bytedance Global"},
    {value: "Moloco Ads", label: "Moloco Ads"},
    {value: "chartboosts2s_int", label: "Chartboost"},
    {value: "mintegral_int", label: "Mintegral"}
]

export class FilterDef {

    static NEW_LINE = {
        type: FilterType.NEW_LINE,
    }

    static TITLE = {
        key: 'app_name',
        title: 'Title',
        type: FilterType.MULTISELECT,
        optionsFunc: () => CatalogApi.getAppNameOptions(),
        defaultValue: DEFAULT_MULTISELECT_VALUE,
    }

    static APP = {
        key: 'full_app_name',
        title: 'App',
        type: FilterType.MULTISELECT,
        optionsFunc: () => CatalogApi.getFullAppNameOptionsUarm(),
        defaultValue: DEFAULT_MULTISELECT_VALUE,
    }

    static PLATFORM = {
        key: 'platform',
        title: 'Platform',
        type: FilterType.MULTISELECT,
        optionsFunc: () => CatalogApi.getPlatformOptions(),
        defaultValue: DEFAULT_MULTISELECT_VALUE,
    }

    static MEDIA_SOURCE = {
        key: 'media_source',
        title: 'Media Source',
        type: FilterType.MULTISELECT,
        optionsFunc: () => CatalogApi.getMediaSourceOptions(),
        defaultValue: DEFAULT_MULTISELECT_VALUE,
    }

    static CAMPAIGN = {
        key: 'campaign',
        title: 'Campaign',
        type: FilterType.INPUT,
        defaultValue: '',
    }

    static COUNTRY = {
        key: 'country_code',
        title: 'Country',
        type: FilterType.MULTISELECT,
        optionsFunc: () => CatalogApi.getCountriesOptions(),
        defaultValue: DEFAULT_MULTISELECT_VALUE,
    }

    static INSTALL_DATE = {
        key: 'install_date',
        title: 'Date Range',
        type: FilterType.DATE_RANGE,
        defaultValue: {
            startDate: ReportUtil.getDefaultStartDate(),
            endDate: ReportUtil.getDefaultEndDate()
        }
    }

    static TRAFFIC_TYPE = {
        key: 'traffic_type',
        title: 'Traffic Type',
        type: FilterType.MULTISELECT,
        options: TRAFFIC_TYPE_OPTIONS,
        defaultValue: DEFAULT_MULTISELECT_VALUE,
    }

    static CREATIVE_TYPE = {
        key: 'creative_type',
        title: 'Creative Type',
        type: FilterType.SELECT,
        defaultValue: CREATIVE_TYPE_OPTIONS[0],
        options: CREATIVE_TYPE_OPTIONS,
    }

    static CREATIVE_REPORT_COUNTRY = {
        key: 'country_code',
        title: 'Country',
        type: FilterType.MULTISELECT,
        options: CREATIVE_REPORT_COUNTRY_OPTIONS,
        defaultValue: DEFAULT_MULTISELECT_VALUE,
    }

    static CREATIVE_REPORT_MEDIA_SOURCE = {
        key: 'media_source',
        title: 'Media Source',
        type: FilterType.MULTISELECT,
        options: CREATIVE_REPORT_MEDIA_SOURCES,
        defaultValue: DEFAULT_MULTISELECT_VALUE,
    }

    static ASO_SOURCE = {
        key: 'source',
        title: 'Source',
        type: FilterType.MULTISELECT,
        optionsFunc: () => CatalogApi.getAsoSourceOptions(),
        defaultValue: DEFAULT_MULTISELECT_VALUE,
    };

    static WEEK_START = {
        key: 'week_start',
        title: 'Week start',
        type: FilterType.SELECT,
        defaultValue: WEEK_START_OPTIONS[0],
        options: WEEK_START_OPTIONS,
        filterModelFunc: (value) => ({})
    }

    static ADDITIONAL_FILTERS = {
        key: 'additional_filters',
        title: 'Additional Filters',
        type: FilterType.MULTISELECT,
        defaultValue: [],
        options: ADDITIONAL_FILTERS_OPTIONS,
        filterModelFunc: (value) => {
            let filters = {}
            if (value.find((option) => option.value === 'hideAutobidderCampaigns')) {
                filters.campaign = {'filterType': 'string', 'filter': '%_abid_%', 'type': 'not like'};
            }

            if (value.find((option) => option.value === 'hidePaused')) {
                filters.campaign_active = {'filterType': 'boolean', 'filter': true, 'type': 'equal'};
            }

            if (value.find((option) => option.value === 'testOnly')) {
                filters.is_test_campaign = {'filterType': 'boolean', 'filter': true, 'type': 'equal'};
            }

            if (value.find((option) => option.value === 'coreOnly')) {
                filters.is_test_campaign = {'filterType': 'boolean', 'filter': false, 'type': 'equal'};
            }

            if (!value.find((option) => option.value === 'showSKAN')) {
                filters.is_skad = {'filterType': 'boolean', 'filter': false, 'type': 'equals'};
            }

            return filters
        },
        aggregateFilterModelFunc: (value) => {
            let filters = {};
            if (value.find((option) => option.value === 'hideInactive')) {
                filters.installs = {'filterType': 'number', 'filter': 0, 'type': 'notEqual'};
                filters.spend = {'filterType': 'number', 'filter': 0, 'type': 'notEqual'};
            }

            return filters
        }
    }

    static SITE_ID = {
        key: 'siteid',
        title: 'Site ID',
        type: FilterType.INPUT,
        defaultValue: '',
        filterModelFunc: (value) => ({siteid: {'filterType': 'string', 'filter': '%' + value + '%', 'type': 'like'}})
    }
}