import React from 'react';
import '../../app/App.css';
import {AgGridReact} from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import {AllModules, ModuleRegistry} from '@ag-grid-enterprise/all-modules';
import {Button} from "reactstrap";
import {withSnackbar} from 'notistack';
import CustomLoadingCellRenderer from "../../common/components/CustomLoadingCellRenderer";
import CustomLoadingOverlay from "../../common/components/CustomLoadingOverlay";
import CustomNoRowsOverlay from "../../common/components/CustomNoRowsOverlay";

ModuleRegistry.registerModules(AllModules);

class ReportGrid extends React.Component {

    constructor(props) {
        super(props);

        this.onGridReady = this.onGridReady.bind(this)
        this.getContextMenuItems = this.getContextMenuItems.bind(this)
        this.toValueObjects = this.toValueObjects.bind(this)
        this.toCsvHeaders = this.toCsvHeaders.bind(this)
        this.getRows = this.getRows.bind(this)
        this.onGroupExpandedOrCollapsed = this.onGroupExpandedOrCollapsed.bind(this)
        this.cellRendererParams = this.cellRendererParams.bind(this)

        this.state = {
            modules: AllModules,
            rowData: undefined,
            columnDefs: props.columnDefs,
            defaultSortingModel: props.defaultSortingModel,
            defaultGroupings: props.defaultGroupings,
            loadRowsFunctions: props.loadRowsFunctions,
            exportFunction: props.exportFunction,
            gridReady: props.gridReady,

            defaultColDef: {
                resizable: true,
                enableValue: true,
                enableRowGroup: true,
                enablePivot: false,
                sortable: true,
                filter: true,
                cellRendererParams: this.cellRendererParams
            },

            frameworkComponents: {
                customLoadingCellRenderer: CustomLoadingCellRenderer,
                customLoadingOverlay: CustomLoadingOverlay,
                customNoRowsOverlay: CustomNoRowsOverlay,
                campaignActivityRenderer: (params) => {
                    let campaignStatus = this.props.activeCampaigns[params.value]
                        ? <span className="green-dot"/>
                        : <span className="red-dot"/>;
                    let roasDynamics = <span/>
                    if (params.value && params.value.toLowerCase().match('(ba|cm|mwe|rog)_.*')) {
                        roasDynamics = <a
                            href={`https://tableau.belka-games.com/#/views/RoASDynamicsTriangle2/sheet0?Campaign=${params.value}&App%20Code=ba,cm,mwe`}
                            target="_blank" style={{'cursor': 'pointer'}}>
                            &#128208;&nbsp;
                        </a>

                    }


                    return <div style={{
                        "display": 'flex',
                        'alignItems': 'center'
                    }}>{campaignStatus}&nbsp;{roasDynamics}{params.value}</div>
                }
            },

            loadingCellRenderer: "customLoadingCellRenderer",
            loadingCellRendererParams: {loadingMessage: "One moment please..."},
            loadingOverlayComponent: "customLoadingOverlay",
            loadingOverlayComponentParams: {loadingMessage: "Loading..."},
            noRowsOverlayComponent: "customNoRowsOverlay",
            noRowsOverlayComponentParams: {
                noRowsMessageFunc: function () {
                    return "Nothing found!";
                }
            },
            sideBar: {
                toolPanels: [
                    {
                        id: 'columns',
                        labelDefault: 'Columns',
                        labelKey: 'columns',
                        iconKey: 'columns',
                        toolPanel: 'agColumnsToolPanel',
                        toolPanelParams: {
                            suppressPivotMode: true,
                            suppressValues: true,
                            suppressRowGroups: true
                        }
                    }
                ],
            },
            autoGroupColumnDef: {
                suppressAutoSize: true,
                maxWidth: 600,
                tooltipField: 'campaign',
                suppressMovable: true
            },
            isLoading: false
        };
    }

    toggleLoading() {
        this.setState((prevState, _props) => ({
            isLoading: !prevState.isLoading
        }))
    }

    getRows(params) {
        this.gridApi.showLoadingOverlay();
        this.state.loadRowsFunctions(params.request)
            .then(response => {
                let result = response.body;
                let lastRow = result.data.length === 0 ? -1 : result.lastRow;
                if (result.data.length > 0) {
                    params.successCallback(result.data, lastRow);
                    this.gridApi.hideOverlay();
                } else {
                    this.gridApi.showNoRowsOverlay();
                }
                setTimeout(() => this.gridColumnApi.autoSizeAllColumns(), 100)
            })
            .catch(response => {
                this.gridApi.hideOverlay();
                this.showError(response);
            });
    };

    showError(message) {
        const action = key => (
            <React.Fragment>
                <Button color="danger" onClick={() => {
                    this.props.closeSnackbar(key)
                }}>
                    'Close'
                </Button>
            </React.Fragment>
        );
        this.props.enqueueSnackbar(message, {
            variant: 'error',
            autoHideDuration: 10000,
            action
        });
    }

    onGridReady(params) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.gridApi.sizeColumnsToFit();
        this.gridColumnApi.setRowGroupColumns(this.state.defaultGroupings);
        this.gridApi.setSortModel(this.state.defaultSortingModel);
        this.gridApi.setServerSideDatasource({getRows: this.getRows});
        this.gridApi.addEventListener("modelUpdated", this.onGroupExpandedOrCollapsed);
        if (this.state.gridReady) {
            this.state.gridReady(params)
        }
    };

    getContextMenuItems() {
        return ['copy']
    }

    processCellForClipboard(params) {
        let colDef = params.column.getColDef();
        if (colDef.valueFormatter) {
            return colDef.valueFormatter({
                ...params,
                data: params.node?.data,
                colDef: colDef
            })
        }

        return params.value;
    }

    toValueObjects(columns) {
        return columns.map(function (col) {
            return ({
                id: col.getId(),
                aggFunc: col.getAggFunc(),
                field: col.getColDef().field
            });
        })
    }

    toCsvHeaders(columns) {
        return columns
            .filter(col => col.getColDef().headerName !== 'Group')
            .map(col => ({
                name: col.getColDef().headerName,
                field: col.getColDef().field || "virtual",
                colId: col.getColDef().colId
            }))
    }

    onGroupExpandedOrCollapsed(params) {
        params.columnApi.autoSizeColumn("ag-Grid-AutoColumn");
    }

    triggerFilterChanged() {
        this.gridApi.onFilterChanged();
    }

    showLoadingOverlay() {
        this.gridApi.showLoadingOverlay();
    }

    hideLoadingOverlay() {
        this.gridApi.hideOverlay()
    }

    showExportError(response) {
        let text = response.status === 413
            ? "Export dataset is more then 500_000 rows. Please, add more filters and repeat export."
            : "Error: " + response;

        this.showError(text);
        this.hideLoadingOverlay();
    }

    makeRequestForExport() {
        let columnController = this.gridApi.columnController;
        let toGroupKey = columns => columns.map(col => col.getColDef().field);
        let csvHeaders = this.toCsvHeaders(columnController.getRowGroupColumns());
        csvHeaders = csvHeaders.concat(this.toCsvHeaders(columnController.getAllDisplayedColumns()));

        return {
            "rowGroupCols": this.toValueObjects(columnController.getRowGroupColumns()),
            "groupKeys": toGroupKey(columnController.getRowGroupColumns()),
            "valueCols": this.toValueObjects(columnController.getValueColumns()),
            "csvHeaders": csvHeaders,
            "pivotCols": this.toValueObjects(columnController.getPivotColumns()),
            "pivotMode": columnController.isPivotMode(),
            "filterModel": null,
            "sortModel": this.gridApi.serverSideRowModel.extractSortModel()
        }
    }

    cellRendererParams() {
        return this.props.cellRendererParams
    }

    render() {
        return (
            <div className="text-center">
                <div className="children">
                    <div className="block" style={{height: "100vh"}}>
                        <div className="ag-theme-balham" style={{height: "100%"}}>
                            <AgGridReact
                                enableSorting={true}
                                enableRangeSelection={true}
                                processCellForClipboard={this.processCellForClipboard}
                                rowGroupPanelShow={"always"}
                                getContextMenuItems={this.getContextMenuItems}
                                enableColResize={true}
                                defaultColDef={this.state.defaultColDef}
                                autoGroupColumnDef={this.state.autoGroupColumnDef}
                                sideBar={this.state.sideBar}
                                groupSelectsChildren={true}
                                suppressAggFuncInHeader={true}
                                serverSideStoreType={'partial'}
                                columnDefs={this.state.columnDefs}
                                rowData={this.state.rowData}
                                modules={this.state.modules}
                                onGridReady={this.onGridReady}
                                rowModelType={'serverSide'}
                                cacheBlockSize={100}
                                suppressMakeColumnVisibleAfterUnGroup={true}
                                maxBlocksInCache={10}
                                tooltipShowDelay={0}
                                multiSortKey={"ctrl"}
                                rowSelection={"single"}
                                frameworkComponents={this.state.frameworkComponents}
                                loadingCellRenderer={this.state.loadingCellRenderer}
                                loadingCellRendererParams={this.state.loadingCellRendererParams}
                                loadingOverlayComponent={this.state.loadingOverlayComponent}
                                loadingOverlayComponentParams={this.state.loadingOverlayComponentParams}
                                noRowsOverlayComponent={this.state.noRowsOverlayComponent}
                                noRowsOverlayComponentParams={this.state.noRowsOverlayComponentParams}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withSnackbar(ReportGrid);


