import {
    AD,
    AD_FCST360,
    AD_FCST_360_D7_FIXED,
    AD_REVENUE0D,
    AD_REVENUE14D,
    AD_REVENUE1D,
    AD_REVENUE30D,
    AD_REVENUE3D,
    AD_REVENUE7D,
    ADJUST_SITEID,
    ADSET,
    CAMPAIGN,
    CLICKS,
    CONVERSIONS,
    COUNTRY,
    CPA3D,
    CPI,
    CPM,
    DATE,
    DAU14D,
    DAU1D,
    DAU3D,
    DAU7D,
    EXCHANGE,
    FCST360,
    FCST360_D7_FIXED,
    GROSS_REVENUE,
    GROSS_REVENUE0D,
    GROSS_REVENUE14D,
    GROSS_REVENUE30D,
    GROSS_REVENUE3D,
    GROSS_REVENUE7D,
    GROSS_ROAS0D,
    GROSS_ROAS14D,
    GROSS_ROAS30D,
    GROSS_ROAS3D,
    GROSS_ROAS7D,
    IMPRESSIONS,
    INST14D,
    INST1D,
    INST2D,
    INST3D,
    INST7D,
    IPM,
    KEYWORDS,
    LTV360,
    MEDIA_SOURCE,
    NEG_DAU1D,
    NEG_DAU2D,
    NEG_DAU3D,
    NEG_DAU7D,
    NEG_RET1D,
    NEG_RET2D,
    NEG_RET3D,
    NEG_RET7D,
    NET_REVENUE,
    PAYERS,
    PAYERS3,
    PAYERS3_PERCENT,
    PAYERS7,
    PAYERS7_PERCENT,
    PAYERS_PERCENT,
    PAYMENTS3,
    PLATFORM,
    PROFIT_360,
    REENG,
    REENG_PERCENT,
    RET14D,
    RET1D,
    RET3D,
    RET7D,
    REVENUE0D,
    REVENUE14D,
    REVENUE1D,
    REVENUE30D,
    REVENUE3D,
    REVENUE7D,
    ROAS,
    ROAS0D_FACT,
    ROAS14D_FACT,
    ROAS1D_FACT,
    ROAS30D,
    ROAS3D_FACT,
    ROAS7D_FACT,
    ROAS_DRIFTING,
    SPEND,
    TITLE,
    VTA,
    VTA_PERCENT,
    WEEK
} from "../common/ColumnDefs";
import {show} from "../common/Utils";
import {FilterDef} from "./common/ReportFiltersDef";

export const RETARGETING_REPORT_COLUMNS = [
    WEEK, TITLE, PLATFORM, DATE, MEDIA_SOURCE, CAMPAIGN, ADSET, COUNTRY, ADJUST_SITEID, EXCHANGE, AD, KEYWORDS,
    CONVERSIONS, REENG, REENG_PERCENT, PAYERS_PERCENT, CPI, SPEND, ROAS, PROFIT_360, ROAS_DRIFTING, LTV360,
    ROAS0D_FACT, ROAS1D_FACT, ROAS3D_FACT, ROAS7D_FACT, show(ROAS14D_FACT), ROAS30D, RET1D, RET3D, RET7D, RET14D, NEG_RET1D, NEG_RET2D,
    NEG_RET3D, NEG_RET7D, NET_REVENUE, GROSS_ROAS7D, GROSS_REVENUE, IMPRESSIONS, CLICKS, PAYERS, PAYERS3,
    PAYERS3_PERCENT, PAYERS7, PAYERS7_PERCENT, GROSS_ROAS0D, REVENUE1D, GROSS_REVENUE3D, REVENUE3D, GROSS_ROAS3D,
    GROSS_REVENUE7D, REVENUE7D, GROSS_REVENUE14D, REVENUE14D, GROSS_ROAS14D, GROSS_REVENUE30D, REVENUE30D,
    GROSS_ROAS30D, VTA, VTA_PERCENT, REVENUE0D, GROSS_REVENUE0D, INST1D, INST2D, INST3D, INST7D, INST14D, DAU1D,
    NEG_DAU1D, NEG_DAU2D, DAU3D, NEG_DAU3D, DAU7D, NEG_DAU7D, DAU14D, AD_REVENUE0D, AD_REVENUE1D, AD_REVENUE3D,
    AD_REVENUE7D, AD_REVENUE14D, AD_REVENUE30D, IPM, CPM, CPA3D, PAYMENTS3, FCST360, AD_FCST360, FCST360_D7_FIXED,
    AD_FCST_360_D7_FIXED,
]

export const RETARGETING_REPORT_DEFAULT_GROUPINGS = [
    MEDIA_SOURCE.colId, TITLE.colId, PLATFORM.colId, CAMPAIGN.colId, WEEK.colId, DATE.colId, ADSET.colId, COUNTRY.colId, ADJUST_SITEID.colId, AD.colId
];

export const RETARGETING_REPORT_DEFAULT_SORTING_MODEL = [
    {colId: WEEK.colId, sort: "asc"},
    {colId: DATE.colId, sort: "asc"},
    {colId: SPEND.colId, sort: "desc"}
]

export const RETARGETING_REPORT_FILTERS_CONFIG = [
    FilterDef.TITLE,
    FilterDef.PLATFORM,
    FilterDef.MEDIA_SOURCE,
    FilterDef.COUNTRY,
    FilterDef.CAMPAIGN,
    FilterDef.INSTALL_DATE,
]


