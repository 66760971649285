import React from "react";
import {Spinner} from "reactstrap";
import Dropdown from "reactstrap/es/Dropdown";
import DropdownToggle from "reactstrap/es/DropdownToggle";
import DropdownMenu from "reactstrap/es/DropdownMenu";
import {ReportApi} from "../../api/ReportApi";
import {ReportUtil} from "./ReportUtils";

class ProfileDropdown extends React.Component {

    constructor(props) {
        super(props);
        this.profileKey = props.profileKey
        this.state = {
            showProfileSpinner: false,
            showProfileDropDown: false,
            availableProfiles: [],
            selectedProfile: ReportUtil.getProfileFromLocalStorage(this.profileKey)
        }

        this.toggleProfileDropdown = this.toggleProfileDropdown.bind(this)
        this.loadAvailableProfiles = this.loadAvailableProfiles.bind(this)
        this.handleProfileChoose = this.handleProfileChoose.bind(this)
        this.updateSelectedProfile = this.updateSelectedProfile.bind(this)
        this.handleProfileDelete = this.handleProfileDelete.bind(this)
        this.handleProfileCancel = this.handleProfileCancel.bind(this)
        this.handleProfileRefresh = this.handleProfileRefresh.bind(this)
        this.toggleSpinner = this.toggleSpinner.bind(this)
    }

    componentDidMount() {
        this.loadAvailableProfiles();
    }

    loadAvailableProfiles() {
        this.toggleSpinner(true);
        ReportApi.listAvailableProfiles(this.profileKey)
            .then(resp => this.setState((prevState) => ({...prevState, availableProfiles: resp.body})))
            .finally(() => this.toggleSpinner(false))
    }

    toggleSpinner(show) {
        this.setState((prevState) => ({...prevState, showProfileSpinner: show}))
    }

    toggleProfileDropdown() {
        this.setState((prevState) => ({...prevState, showProfileDropDown: !prevState.showProfileDropDown}))
    }

    handleProfileChoose(selectedProfile) {
        ReportUtil.saveProfileToLocalStorage(this.profileKey, selectedProfile)
        this.updateSelectedProfile(selectedProfile);
        this.toggleProfileDropdown();
        this.props.handleProfileChoose(selectedProfile)
    }

    updateSelectedProfile(selectedProfile) {
        this.setState((prevState) => ({...prevState, selectedProfile}))
    }

    handleProfileDelete(event, profileId) {
        event.stopPropagation()
        this.toggleSpinner(true)
        ReportApi.deleteProfile(profileId)
            .then(response => {
                if (this.state.selectedProfile && this.state.selectedProfile.id === profileId) {
                    this.setState((prevState) => ({...prevState, selectedProfile: null}));
                    this.props.handleProfileCancel()
                    ReportUtil.removeProfileFromLocalStorage(this.profileKey)
                }

                let availableProfiles = response.body;
                this.setState((prevState) => ({...prevState, availableProfiles}));

                if (availableProfiles.length === 0) this.toggleProfileDropdown()
            })
            .finally(() => this.toggleSpinner(false))
    }

    handleProfileCancel(event) {
        event.stopPropagation()
        ReportUtil.removeProfileFromLocalStorage(this.profileKey)
        this.setState((prevState) => ({...prevState, selectedProfile: null}))
        this.props.handleProfileCancel()
    }

    handleProfileRefresh(event) {
        event.stopPropagation()
        this.toggleSpinner(true)
        this.props.handleProfileRefresh(this.state.selectedProfile)
            .then(savedProfile => {
                this.setState((prevState) => ({...prevState, selectedProfile: savedProfile}))
                ReportUtil.saveProfileToLocalStorage(this.profileKey, savedProfile)
                this.loadAvailableProfiles()
            })
            .finally(() => this.toggleSpinner(false))
    }

    render() {
        return (
            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%'}}>
                <div style={{minHeight: '10px'}}/>
                <Dropdown isOpen={this.state.showProfileDropDown}
                          toggle={this.toggleProfileDropdown} style={{width: '100%'}}>
                    <DropdownToggle caret={false} style={{width: '100%'}}>
                        {this.state.selectedProfile &&
                        <span style={{float: 'left'}}>
                            <span onClick={this.handleProfileCancel}>&times;&nbsp;&nbsp;</span>
                            <span onClick={this.handleProfileRefresh}>&#x21BB;&nbsp;&nbsp;</span>
                        </span>
                        }

                        {this.state.showProfileSpinner && <span><Spinner size="sm"> </Spinner>&nbsp;&nbsp;&nbsp;</span>}
                        <span>{this.state.selectedProfile ? this.state.selectedProfile.name : 'Select profile'}</span>
                        <span style={{float: 'right'}}>&#9662;</span>
                    </DropdownToggle>

                    <DropdownMenu style={{'width': '20rem'}}>
                        {this.state.availableProfiles.map((profile) => (
                            <div key={profile.id} className="dropdown-item"
                                 style={{'cursor': 'pointer', 'display': 'inline-block'}}>
                                <div style={{'float': 'left', 'width': '90%'}}
                                     onClick={() => this.handleProfileChoose(profile)}>{profile.name}
                                </div>
                                <div style={{'float': 'right'}}
                                     onClick={(event) => this.handleProfileDelete(event, profile.id)}>
                                    &#x2715;
                                </div>
                            </div>
                        ))}
                    </DropdownMenu>
                </Dropdown>
            </div>
        )
    }
}

export default ProfileDropdown;
