import React from 'react';
import {withSnackbar} from "notistack";
import {Card, Col, Input, Label, Row} from "reactstrap";
import MultiSelect from "../../components/CustomMultiSelect/MultiSelect";
import DateRangeSelect from "../../searchpanel/datepicker/DateRangeSelect";
import Select from "react-select";
import {FilterType, ReportUtil} from "./ReportUtils";
import ProfileDropdown from "./ProfileDropdown";
import SearchButton from "./SearchButton";
import {objectWithoutKey} from "../../common/Utils";

class ReportFilters extends React.Component {

    constructor(props) {
        super(props);
        this.profilesDropdown = React.createRef();
        this.filterDefs = props.filterDefs
        this.profileKey = this.props.profileKey
        const localStorageProfile = ReportUtil.getProfileFromLocalStorage(this.props.profileKey) || {filters: {}}
        this.state = {
            values: {...ReportUtil.buildInitialValues(this.filterDefs), ...localStorageProfile.filters},
            options: ReportUtil.buildInitialOptions(this.filterDefs),
        }
        this.handleProfileChoose = this.handleProfileChoose.bind(this)
        this.handleProfileSave = this.handleProfileSave.bind(this)
        this.handleProfileCancel = this.handleProfileCancel.bind(this)
        this.handleProfileRefresh = this.handleProfileRefresh.bind(this)
        this.onSearchClick = this.onSearchClick.bind(this)
        this.onExportClick = this.onExportClick.bind(this)
    }

    componentDidMount() {
        this.initSelectOptionsAsync()
        this.props.onSearchClick(this.state.values)
    }

    initSelectOptionsAsync() {
        this.filterDefs
            .filter(filterConfig => !!filterConfig.optionsFunc)
            .forEach(
                filterConfig => filterConfig.optionsFunc()
                    .then(options => this.setState((prevState) => ({
                            ...prevState, options: {...prevState.options, [filterConfig.key + '_options']: options}
                        }
                    )))
            )
    }

    getMultiSelectComponent(filterConfig) {
        return <MultiSelect
            id={filterConfig.key}
            label={filterConfig.title}
            options={this.state.options[filterConfig.key + '_options']}
            value={(this.state.values)[filterConfig.key]}
            onChange={(options) => this.setState((prevState) => {
                let values = {...prevState.values, [filterConfig.key]: options};

                return ({...prevState, values});
            })}
        />;
    }

    getDateRangeComponent(filterConfig) {
        const dateRangeChangeFunc = (start, end) => {
            this.setState((prevState) => {
                let values = {
                    ...prevState.values,
                    [filterConfig.key]: {'startDate': start.format("YYYY-MM-DD"), 'endDate': end.format("YYYY-MM-DD")}
                };
                return ({...prevState, values});
            });
        }

        return <React.Fragment>
            <Label>{filterConfig.title}</Label>
            <DateRangeSelect onDatePickerChange={dateRangeChangeFunc}/>
        </React.Fragment>;
    }

    getInputComponent(filterConfig) {
        const inputChangeFunc = (event) => {
            const value = event.target.value;
            this.setState(prevState => {
                    let values = {...prevState.values, [filterConfig.key]: value};
                    return ({...prevState, values});
                }
            )
        }
        return <React.Fragment>
            <Label>{filterConfig.title}</Label>
            <Input type="text"
                   id={filterConfig.key}
                   value={(this.state.values)[filterConfig.key]}
                   onChange={inputChangeFunc}/>
        </React.Fragment>;
    }

    getSelectComponent(filterConfig) {
        return <React.Fragment>
            <Label>{filterConfig.title}</Label>
            <Select
                id={filterConfig.key}
                name={filterConfig.key}
                value={(this.state.values)[filterConfig.key]}
                options={this.state.options[filterConfig.key + '_options']}
                onChange={(options) => this.setState(prevState => {
                    let values = {...prevState.values, [filterConfig.key]: options};
                    return ({...prevState, values});
                })}/>
        </React.Fragment>
    }

    getCheckboxComponent(filterConfig) {
        return <React.Fragment>
            <Label>
                <Input
                    id={filterConfig.key}
                    type="checkbox"
                    checked={(this.state.values)[filterConfig.key]}
                    onChange={(event) => this.setState(prevState => {
                        let values = {...prevState.values, [filterConfig.key]: event.target.checked};
                        return ({...prevState, values});
                    })}
                />
                {filterConfig.title}
            </Label>
        </React.Fragment>
    }

    handleProfileSave(profileName) {
        let profile = {
            name: profileName,
            key: this.profileKey,
            filters: objectWithoutKey(this.state.values, 'install_date')
        }
        return this.props.handleProfileSave(profile).then((savedProfile) => {
            this.profilesDropdown.current.loadAvailableProfiles()
            this.profilesDropdown.current.updateSelectedProfile(savedProfile)
            ReportUtil.saveProfileToLocalStorage(this.profileKey, savedProfile)
        })
    }

    handleProfileCancel() {
        this.setState(
            (prevState) => ({...prevState, values: ReportUtil.buildInitialValues(this.filterDefs)}),
            () => this.props.handleProfileCancel(this.state.values)
        )
    }

    _getFilteredValues() {
        return this.state.values;
    }

    handleProfileRefresh(selectedProfile) {
        return this.props.handleProfileRefresh({
            ...selectedProfile,
            filters: objectWithoutKey(this.state.values, 'install_date')
        })
    }

    handleProfileChoose(selectedProfile) {
        const filters = {...this.state.values, ...selectedProfile.filters}
        this.setState((prevState) => ({...prevState, values: filters}))
        this.props.handleProfileChoose(filters, selectedProfile.columns, selectedProfile.grouping)
    }

    onSearchClick() {
        this.props.onSearchClick(this.state.values)
    }

    onExportClick() {
        this.props.onExportClick(this.state.values)
    }

    render() {
        const getFilterField = (filterConfig) => {
            if (filterConfig.type === FilterType.MULTISELECT) {
                return this.getMultiSelectComponent(filterConfig)
            } else if (filterConfig.type === FilterType.INPUT) {
                return this.getInputComponent(filterConfig)
            } else if (filterConfig.type === FilterType.DATE_RANGE) {
                return this.getDateRangeComponent(filterConfig)
            } else if (filterConfig.type === FilterType.SELECT) {
                return this.getSelectComponent(filterConfig)
            } else if (filterConfig.type === FilterType.CHECKBOX) {
                return this.getCheckboxComponent(filterConfig)
            }
        }

        return (
            <Card className="block form">
                <Row xs="5">
                    {this.filterDefs.map(function (filterConfig, _i) {
                        if (filterConfig.type === FilterType.NEW_LINE) {
                            return <div key="newRow" className="w-100"/>
                        } else {
                            return <Col key={filterConfig.key}>{getFilterField(filterConfig)}</Col>
                        }
                    })}
                    <Col>
                        <SearchButton onSearchClick={this.onSearchClick}
                                      onExportClick={this.onExportClick}
                                      handleProfileSave={this.handleProfileSave}/>
                    </Col>
                    <Col>
                        <ProfileDropdown ref={this.profilesDropdown}
                                         profileKey={this.profileKey}
                                         handleProfileChoose={this.handleProfileChoose}
                                         handleProfileCancel={this.handleProfileCancel}
                                         handleProfileRefresh={this.handleProfileRefresh}/>
                    </Col>
                </Row>
            </Card>
        );
    }
}

export default withSnackbar(ReportFilters);
