import {hide, show} from "../common/Utils";
import {
    AD,
    AD_FCST120,
    AD_FCST180,
    AD_FCST360,
    AD_FCST360_CF_D7_FIXED_TEST,
    AD_FCST360_CF_TEST,
    AD_FCST90,
    AD_LTV360_CF_COMBINE,
    AD_LTV360_CF_TEST,
    AD_LTV360_OLD_7D,
    AD_REVENUE,
    ADJUST_SITEID,
    ADJUST_SUB_SITEID,
    ADSET,
    AVG_COHORT_DAY,
    C2W,
    C_RET1D,
    C_RET2D,
    CAMPAIGN,
    CLICKS,
    COUNTRY,
    CPA,
    CPA14D,
    CPA1D,
    CPA2D,
    CPA3D,
    CPA7D,
    CPI,
    CPI_LOGIN_COMPLETE,
    CPM,
    CPP,
    CPP14D,
    CPP1D,
    CPP2D,
    CPP3D,
    CPP7D,
    CPW,
    CREATIVE,
    CREATIVE_CONCEPT,
    CS,
    CTR,
    DATE,
    DAU14D,
    DAU1D,
    DAU2D,
    DAU30D,
    DAU3D,
    DAU7D,
    DEVICE_CATEGORY,
    EXCHANGE,
    FACT_LTV360,
    FB_AUDIENCE,
    FB_COUNTRY_TYPE,
    FB_DEVICE,
    FB_OPTIMIZATION,
    FB_PLACEMENT,
    FCST120,
    FCST180,
    FCST360,
    FCST360_CF_D7_FIXED_TEST,
    FCST360_CF_TEST,
    FCST360_D7_FIXED,
    FCST360_NEW,
    FCST90,
    FULL_APP_NAME,
    GROSS_REVENUE,
    GROSS_REVENUE0D,
    GROSS_REVENUE14D,
    GROSS_REVENUE1D,
    GROSS_REVENUE2D,
    GROSS_REVENUE30D,
    GROSS_REVENUE3D,
    GROSS_REVENUE7D,
    GROSS_ROAS0D,
    GROSS_ROAS14D,
    GROSS_ROAS1D,
    GROSS_ROAS2D,
    GROSS_ROAS30D,
    GROSS_ROAS3D,
    GROSS_ROAS7D,
    IMPRESSIONS,
    INAPP_FCST360,
    INAPP_REVENUE,
    INST14D,
    INST1D,
    INST2D,
    INST30D,
    INST3D,
    INST7D,
    INSTALLS,
    IPM,
    IPM_LOGIN_COMPLETE,
    IR,
    KEYWORDS,
    LOGIN_COMPLETE,
    LOGIN_COMPLETE_14D,
    LOGIN_COMPLETE_1D,
    LOGIN_COMPLETE_2D,
    LOGIN_COMPLETE_30D,
    LOGIN_COMPLETE_3D,
    LOGIN_COMPLETE_7D,
    LOGIN_COMPLETE_DAU14D,
    LOGIN_COMPLETE_DAU1D,
    LOGIN_COMPLETE_DAU2D,
    LOGIN_COMPLETE_DAU30D,
    LOGIN_COMPLETE_DAU3D,
    LOGIN_COMPLETE_DAU7D,
    LOGIN_COMPLETE_FACT_ROAS_14D,
    LOGIN_COMPLETE_FACT_ROAS_1D,
    LOGIN_COMPLETE_FACT_ROAS_30D,
    LOGIN_COMPLETE_FACT_ROAS_3D,
    LOGIN_COMPLETE_FACT_ROAS_60D,
    LOGIN_COMPLETE_FACT_ROAS_7D,
    LOGIN_COMPLETE_FACT_ROAS_90D,
    LOGIN_COMPLETE_FCST360,
    LOGIN_COMPLETE_NET_REVENUE,
    LOGIN_COMPLETE_NET_REVENUE_1,
    LOGIN_COMPLETE_NET_REVENUE_14,
    LOGIN_COMPLETE_NET_REVENUE_3,
    LOGIN_COMPLETE_NET_REVENUE_30,
    LOGIN_COMPLETE_NET_REVENUE_60,
    LOGIN_COMPLETE_NET_REVENUE_7,
    LOGIN_COMPLETE_NET_REVENUE_90,
    LOGIN_COMPLETE_PAYERS,
    LOGIN_COMPLETE_PAYERS1,
    LOGIN_COMPLETE_PAYERS1_PERCENT,
    LOGIN_COMPLETE_PAYERS2,
    LOGIN_COMPLETE_PAYERS2_PERCENT,
    LOGIN_COMPLETE_PAYERS3,
    LOGIN_COMPLETE_PAYERS3_PERCENT,
    LOGIN_COMPLETE_PAYERS7,
    LOGIN_COMPLETE_PAYERS7_PERCENT,
    LOGIN_COMPLETE_PAYERS_PERCENT,
    LOGIN_COMPLETE_RET_14D,
    LOGIN_COMPLETE_RET_1D,
    LOGIN_COMPLETE_RET_2D,
    LOGIN_COMPLETE_RET_30D,
    LOGIN_COMPLETE_RET_3D,
    LOGIN_COMPLETE_RET_7D,
    LOGIN_COMPLETE_ROAS,
    LTV360_CF_COMBINE,
    LTV360_CF_TEST,
    LTV360_OLD,
    LTV360_OLD_7D,
    LTVPPU_CF_COMBINE,
    MATCH_TYPE,
    MEDIA_SOURCE,
    MONTH,
    NET_REVENUE,
    OFW_REVENUE,
    OS_VERSION,
    PAYERS,
    PAYERS1,
    PAYERS14,
    PAYERS1_PERCENT,
    PAYERS2,
    PAYERS2_PERCENT,
    PAYERS3,
    PAYERS3_PERCENT,
    PAYERS7,
    PAYERS7_PERCENT,
    PAYERS_PERCENT,
    PAYMENTS,
    PAYMENTS1,
    PAYMENTS14,
    PAYMENTS2,
    PAYMENTS3,
    PAYMENTS7,
    PLATFORM,
    PROFIT_360,
    REENG,
    REENG_PERCENT,
    RELEASE,
    RET14D,
    RET1D,
    RET2D,
    RET30D,
    RET3D,
    RET7D,
    REVENUE0D,
    REVENUE14D,
    REVENUE180D,
    REVENUE1D,
    REVENUE2D,
    REVENUE30D,
    REVENUE360D,
    REVENUE3D,
    REVENUE60D,
    REVENUE7D,
    REVENUE90D,
    ROAS,
    ROAS0D_FACT,
    ROAS120,
    ROAS14D_FACT,
    ROAS180,
    ROAS180D,
    ROAS1D_FACT,
    ROAS2D_FACT,
    ROAS30D,
    ROAS3D_FACT,
    ROAS60D,
    ROAS7D_FACT,
    ROAS90,
    ROAS90D,
    ROAS_DRIFTING,
    ROAS_MAX,
    ROAS_MIN,
    ROAS_OLD,
    ROAS_TEST,
    ROAS_TEST_DRIFTING,
    RPM1D,
    RPM2D,
    SITE_NAME,
    SPEND,
    SUB_GROSS_REVENUE,
    SUB_NET_REVENUE,
    TITLE,
    VTA,
    VTA_PERCENT,
    WEEK_NORMALIZED,
    WHALES
} from "../common/ColumnDefs";
import {FilterDef} from "./common/ReportFiltersDef";

export const UAM_REPORT_COLUMNS = [
    MONTH, WEEK_NORMALIZED, TITLE, FULL_APP_NAME, PLATFORM, DATE, MEDIA_SOURCE, FB_COUNTRY_TYPE, CAMPAIGN, FB_PLACEMENT,
    FB_OPTIMIZATION, FB_AUDIENCE, FB_DEVICE, CREATIVE_CONCEPT, ADSET, COUNTRY, ADJUST_SITEID, ADJUST_SUB_SITEID, SITE_NAME,
    CREATIVE, AD, EXCHANGE, KEYWORDS, DEVICE_CATEGORY, OS_VERSION, MATCH_TYPE, RELEASE, INSTALLS, SPEND, VTA_PERCENT, ROAS, ROAS90,
    ROAS120, ROAS180, PROFIT_360, ROAS_DRIFTING, ROAS_TEST_DRIFTING, ROAS_TEST, ROAS_OLD, CPI, hide(CTR), hide(IR),
    LTV360_CF_COMBINE, AD_LTV360_CF_COMBINE, LTV360_CF_TEST, AD_LTV360_CF_TEST, LTV360_OLD_7D, LTV360_OLD, INAPP_FCST360,
    AD_LTV360_OLD_7D, PAYERS_PERCENT, show(PAYERS3_PERCENT), show(PAYERS7_PERCENT), show(PAYERS), ROAS0D_FACT, ROAS1D_FACT,
    ROAS2D_FACT, ROAS3D_FACT, ROAS7D_FACT, ROAS14D_FACT, ROAS30D, ROAS60D, ROAS90D, ROAS180D, IPM, CPM, CPA3D, LTVPPU_CF_COMBINE,
    RET1D, RET2D, RET3D, RET7D, FACT_LTV360, NET_REVENUE, INAPP_REVENUE, AD_REVENUE, OFW_REVENUE, SUB_NET_REVENUE,
    SUB_GROSS_REVENUE, GROSS_ROAS0D, GROSS_ROAS7D, GROSS_REVENUE, IMPRESSIONS, CLICKS, PAYERS3, PAYERS7, GROSS_REVENUE1D,
    GROSS_REVENUE2D, REVENUE1D, REVENUE2D, GROSS_ROAS1D, GROSS_ROAS2D, DAU1D, DAU2D, GROSS_REVENUE3D, REVENUE3D,
    GROSS_ROAS3D, DAU3D, GROSS_REVENUE7D, REVENUE7D, GROSS_ROAS7D, DAU7D, GROSS_REVENUE14D, REVENUE14D,
    DAU14D, C_RET1D, C_RET2D, RPM1D, RPM2D, GROSS_ROAS14D, RET14D, GROSS_REVENUE30D, REVENUE30D, GROSS_ROAS30D,
    DAU30D, RET30D, FCST360, FCST90, FCST120, FCST180, AD_FCST90, AD_FCST120, AD_FCST180, AD_FCST360, FCST360_D7_FIXED,
    FCST360_CF_TEST, AD_FCST360_CF_TEST, FCST360_NEW, FCST360_CF_D7_FIXED_TEST, AD_FCST360_CF_D7_FIXED_TEST, INST1D,
    INST2D, INST3D, INST7D, INST14D, INST30D, CPA, CPA1D, CPA2D, CPA7D, CPA14D, CPP, CPP1D, CPP2D, CPP3D, CPP7D,
    CPP14D, PAYERS1, PAYERS1_PERCENT, PAYERS2, PAYERS2_PERCENT, PAYERS14, PAYMENTS, PAYMENTS1, PAYMENTS2, PAYMENTS3,
    PAYMENTS7, PAYMENTS14, REVENUE0D, GROSS_REVENUE0D, REVENUE60D, REVENUE90D, REVENUE180D, REVENUE360D, VTA, WHALES,
    C2W, CPW, ROAS_MIN, ROAS_MAX, AVG_COHORT_DAY, hide(REENG_PERCENT), REENG, CS, LOGIN_COMPLETE, CPI_LOGIN_COMPLETE,
    IPM_LOGIN_COMPLETE, LOGIN_COMPLETE_1D, LOGIN_COMPLETE_2D, LOGIN_COMPLETE_3D, LOGIN_COMPLETE_7D,
    LOGIN_COMPLETE_14D, LOGIN_COMPLETE_30D, LOGIN_COMPLETE_RET_1D, LOGIN_COMPLETE_RET_2D, LOGIN_COMPLETE_RET_3D,
    LOGIN_COMPLETE_RET_7D, LOGIN_COMPLETE_RET_14D, LOGIN_COMPLETE_RET_30D, LOGIN_COMPLETE_DAU1D, LOGIN_COMPLETE_DAU2D,
    LOGIN_COMPLETE_DAU3D, LOGIN_COMPLETE_DAU7D, LOGIN_COMPLETE_DAU14D, LOGIN_COMPLETE_DAU30D,
    LOGIN_COMPLETE_PAYERS, LOGIN_COMPLETE_PAYERS1, LOGIN_COMPLETE_PAYERS2, LOGIN_COMPLETE_PAYERS3, LOGIN_COMPLETE_PAYERS7,
    LOGIN_COMPLETE_PAYERS_PERCENT, LOGIN_COMPLETE_PAYERS1_PERCENT, LOGIN_COMPLETE_PAYERS2_PERCENT,
    LOGIN_COMPLETE_PAYERS3_PERCENT, LOGIN_COMPLETE_PAYERS7_PERCENT,
    LOGIN_COMPLETE_NET_REVENUE, LOGIN_COMPLETE_NET_REVENUE_1, LOGIN_COMPLETE_NET_REVENUE_3, LOGIN_COMPLETE_NET_REVENUE_7,
    LOGIN_COMPLETE_NET_REVENUE_14, LOGIN_COMPLETE_NET_REVENUE_30, LOGIN_COMPLETE_NET_REVENUE_60, LOGIN_COMPLETE_NET_REVENUE_90,
    LOGIN_COMPLETE_FACT_ROAS_1D, LOGIN_COMPLETE_FACT_ROAS_3D, LOGIN_COMPLETE_FACT_ROAS_7D, LOGIN_COMPLETE_FACT_ROAS_14D,
    LOGIN_COMPLETE_FACT_ROAS_30D, LOGIN_COMPLETE_FACT_ROAS_60D, LOGIN_COMPLETE_FACT_ROAS_90D, LOGIN_COMPLETE_FCST360,
    LOGIN_COMPLETE_ROAS
]

export const UAM_REPORT_DEFAULT_GROUPINGS = [
    FULL_APP_NAME.colId, PLATFORM.colId, MONTH.colId, WEEK_NORMALIZED.colId, MEDIA_SOURCE.colId, CAMPAIGN.colId,
    DATE.colId, COUNTRY.colId, AD.colId, ADJUST_SITEID.colId, ADSET.colId
];

export const UAM_REPORT_DEFAULT_SORTING_MODEL = [
    {colId: MONTH.colId, sort: "asc"},
    {colId: WEEK_NORMALIZED.colId, sort: "asc"},
    {colId: DATE.colId, sort: "asc"},
    {colId: SPEND.colId, sort: "desc"}
]

export const UAM_REPORT_FILTERS_CONFIG = [
    FilterDef.TITLE,
    FilterDef.APP,
    FilterDef.PLATFORM,
    FilterDef.MEDIA_SOURCE,
    FilterDef.CAMPAIGN,
    FilterDef.COUNTRY,
    FilterDef.INSTALL_DATE,
    FilterDef.WEEK_START,
    FilterDef.ADDITIONAL_FILTERS,
    FilterDef.SITE_ID,
]
