import React from "react";
import {Button, Input, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";

class ProfileNameModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            profileName: ''
        }
        this.handleProfileSave = this.handleProfileSave.bind(this)
        this.closeModal = this.closeModal.bind(this)
    }

    handleProfileSave() {
        this.props.handleProfileSave(this.state.profileName)
            .then(() => this.setState(() => ({profileName: ''})))
    }

    closeModal() {
        this.props.closeModal()
        this.setState(() => ({profileName: ''}))
    }

    render() {
        return (
            <Modal isOpen={this.props.showModal} toggle={this.closeModal} unmountOnClose={true}>
                <ModalHeader toggle={this.closeModal}>Define profile name</ModalHeader>
                <ModalBody>
                    <Input type="text"
                           id="profileName"
                           placeholder="Profile name"
                           value={this.state.profileName}
                           onChange={(event) => {
                               const profileName = event.target.value;
                               this.setState(() => ({profileName}))
                           }}/>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={this.handleProfileSave}>
                        Save profile
                    </Button>
                </ModalFooter>
            </Modal>
        )
    }
}

export default ProfileNameModal;
